import request from '@/utils/scm-request'
import requestWms from '@/utils/request'

export function auditPage(params) {
  return request({
    url: `scm-purchase/audit/page`,
    method: 'get',
    params
  })
}

//  取消稽查单
export function cancelAudit(data) {
  return request({
    url: `scm-purchase/audit/cancelAudit`,
    method: 'post',
    data
  })
}
// 根据id查询稽查单
export function auditById(id) {
  return request({
    url: `scm-purchase/audit/${id}`,
    method: 'get'
  })
}

// 获取规则配置
export function spotCheckList(params) {
  return request({
    url: `scm-purchase/auditConfig/list`,
    method: 'get',
    params
  })
}

// 保存规则配置
export function saveConfig(data) {
  return request({
    url: `scm-purchase/auditConfig/saveConfig`,
    method: 'post',
    data
  })
}

// 预备生成稽查检验报告单
export function generateAuditBefore(id) {
  return request({
    url: `scm-purchase/audit/generateAuditBefore/${id}`,
    method: 'get'
  })
}
// 根据id查询稽查报告单
export function auditReport(id) {
  return request({
    url: `scm-purchase/auditReport/${id}`,
    method: 'get'
  })
}
// 稽查检验报告分页
export function auditReportPage(params) {
  return request({
    url: `scm-purchase/auditReport/page`,
    method: 'get',
    params
  })
}
// 稽查检验报告核准
export function approvalAuditReport(data) {
  return request({
    url: `scm-purchase/auditReport/approvalAuditReport`,
    method: 'post',
    data
  })
}

// 补传保函申请单
export function reissueGuarantee(data) {
  return request({
    url: `scm-purchase/auditReport/reissueGuarantee`,
    method: 'post',
    data
  })
}

// 生成稽查报表
export function saveAuditReport(data) {
  return request({
    url: `scm-purchase/auditReport/saveAuditReport`,
    method: 'post',
    data
  })
}

// 品质问题项分页
export function qualityProblemPage(params) {
  return request({
    url: `scm-purchase/qualityProblem/page`,
    method: 'get',
    params
  })
}

// 发货数据导入
export function deliverImport(data) {
  return request({
    url: `scm-purchase/order/deliverImport`,
    method: 'post',
    data
  })
}

// 发货单数据导入预览
export function deliverImportView(data) {
  return request({
    url: `scm-purchase/order/deliverImportView`,
    method: 'post',
    data
  })
}

// 超收解决方案
export function overCollectSolution(data) {
  return request({
    url: `scm-purchase/deliveryImport/overCollectSolution`,
    method: 'post',
    data
  })
}

// 品质项
export function listType(params) {
  return request({
    url: `scm-purchase/qualityProblem/listType`,
    method: 'get',
    params
  })
}

// 扫鞋码
export function sweepShoeCode(data) {
  return request({
    url: `scm-purchase/auditReport/sweepShoeCode`,
    method: 'post',
    data
  })
}

// 获取角色
export function listUserByRoleCode(roleCode) {
  return request({
    url: `scm-purchase/basic/listUserByRoleCode/${roleCode}`,
    method: 'get'
  })
}

// 导出报告
export function exportPdf(id) {
  return request({
    url: `scm-purchase/auditReport/exportPdf/${id}`,
    method: 'get'
  })
}

// LT不达标原因
export function getLtReason() {
  return request({
    url: `scm-purchase/basic/getLtReason`,
    method: 'get'
  })
}

// 未准交原因
export function getDelayReason() {
  return request({
    url: `scm-purchase/basic/getDeliveryDelay`,
    method: 'get'
  })
}

// 导出
export function exportVendor(data, pager) {
  return requestWms({
    url: `base/basevendor/export/?current=${pager.current}&size=${pager.size}&total=${pager.total}`,
    method: 'post',
    data,
    responseType: 'arraybuffer'
  })
}

// 启用/禁用供应商状态
export function disableOrEnable(data) {
  return requestWms({
    url: `/base/basevendor/disableOrEnable`,
    method: 'post',
    data
  })
}

// 240607新增 发货单数据校验
export function deliverImportVerify(data) {
  return request({
    url: `scm-purchase/order/deliverImportVerify`,
    method: 'post',
    data
  })
}
