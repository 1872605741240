<template>
  <div>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">基本信息</span>
      </el-col>
    </el-row>
    <el-form ref="ruleFormStyle" :model="ruleForm" label-width="100px" disabled>
      <el-row class="row-bg">
        <el-col :span="8">
          <el-form-item label="稽查单号" prop="auditCode">
            <el-input v-model="ruleForm.auditCode" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="收货单号">
            <el-input v-model="ruleForm.collectCode" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="供应商">
            <el-input v-model="ruleForm.vendorName" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="稽查日期">
            <el-input v-model="ruleForm.auditDate" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="稽查地点">
            <el-input v-model="ruleForm.auditPlace" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="报检总箱数">
            <el-input v-model="ruleForm.inspectTotalQuantity" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="报检总双数">
            <el-input v-model="ruleForm.inspectTotalPairs" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="抽检标准双数">
            <el-input v-model="ruleForm.spotCheckPairs" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="稽查总箱数">
            <el-input v-model="ruleForm.auditTotalQuantity" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="稽查总双数">
            <el-input v-model="ruleForm.auditTotalPairs" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="预计完成时间">
            <el-input v-model="ruleForm.expectComplateTime" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="创建日期">
            <el-input v-model="ruleForm.createTime" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="实际完成时间">
            <el-input v-model="ruleForm.actualComplateTime" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="稽查工程师">
            <el-input v-model="ruleForm.auditUser" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态">
            <el-input :placeholder="getAuditTypeLabel('AUDIT_STATUS',ruleForm.status)" />
          </el-form-item>
        </el-col>
        <template v-if="ruleForm.status==9||ruleForm.status=='已取消'">
          <el-col :span="8">
            <el-form-item label="取消原因">
              <el-input v-model="ruleForm.cancelReason" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注">
              <el-input v-model="ruleForm.remark" :placeholder="$t('page.inputPlaceholder')" type="textarea" />
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">稽查明细</span>
      </el-col>
    </el-row>
    <el-table
      ref="asetTable"
      class="mt-3 mb-3"
      :loading="asetTableDatasLoading"
      :data="ruleForm.detailList"
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column
        type="index"
        width="50"
        label="序号"
      />
      <el-table-column
        property="purchaseOrderCode"
        label="采购订单"
        min-width="120"
      />
      <el-table-column
        property="style"
        label="Style"
        min-width="110"
      />
      <el-table-column
        property="inspectPairs"
        label="报检双数"
        min-width="100"
      />
      <el-table-column
        property="inspectQuantity"
        label="报检箱数"
        min-width="100"
      />
      <el-table-column
        property="cartonNumber"
        label="双/箱"
        min-width="90"
      />
      <el-table-column
        property="auditPairs"
        label="稽查双数"
        min-width="100"
      />
      <el-table-column
        property="auditQuantity"
        label="稽查箱数"
        min-width="100"
      />
      <el-table-column
        property="locationCode"
        label="稽查库位"
        min-width="100"
      />
    </el-table>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">稽查报告</span>
      </el-col>
    </el-row>
    <el-table
      ref="reportTable"
      class="mt-3 mb-3"
      :loading="asetTableDatasLoading"
      :data="ruleForm.auditReportVOList"
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column
        type="index"
        width="50"
        label="序号"
      />
      <el-table-column
        property="auditReportCode"
        label="报告编号"
        width="200"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="toInspectDetail(scope.row.id)">{{ scope.row.auditReportCode }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        property="generateDate"
        label="报告生成日期"
        min-width="110"
      />
      <el-table-column
        property="purchaseOrderCode"
        label="采购订单号"
        min-width="100"
      />
      <el-table-column
        property="style"
        label="Style"
        min-width="110"
      />
      <el-table-column
        property="shoeBatchNo"
        label="鞋印批次号"
        min-width="100"
      />
      <el-table-column
        property="inspectTotalPairs"
        label="报检总双数"
        min-width="90"
      />
      <el-table-column
        property="auditTotalPairs"
        label="稽查总双数"
        min-width="100"
      />
      <el-table-column
        property="auditRate"
        label="稽查占比%"
        min-width="100"
      />
      <el-table-column
        property="unqualifiedPairs"
        label="不合格双数"
        min-width="100"
      />
      <el-table-column
        property="sampleUnqualifiedRate"
        label="抽样不良占比%"
        min-width="120"
      />
      <el-table-column
        property="auditResult"
        label="整批稽查结论"
        min-width="120"
      >
        <template slot-scope="scope">
          {{ getAuditTypeLabel('AUDIT_RESULT',scope.row.auditResult) }}
        </template>
      </el-table-column>
      <el-table-column
        property="unqualifiedHandleResult"
        label="不合格处理结论"
        min-width="120"
      >
        <template slot-scope="scope">
          {{ getAuditTypeLabel('UNQUALIFIED_HANDLE_RESULT',scope.row.unqualifiedHandleResult) }}
        </template>
      </el-table-column>
      <el-table-column
        property="reportUser"
        label="报告人"
        min-width="100"
      />
      <el-table-column
        property="approvalUser"
        label="核准人"
        min-width="100"
      />
      <el-table-column
        label="状态"
        min-width="90"
      >
        <template slot-scope="scope">
          {{ getAuditTypeLabel('AUDIT_REPORT_STATUS',scope.row.status) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { auditById } from '@/api/audit-api.js'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'

export default {
  components: { },
  mixins: [commodityInfoDict],

  data() {
    return {
      ruleForm: {
        detailList: []
      },
      asetTableDatasLoading: false
    }
  },
  created() {

  },
  mounted() {
    this.handleDetail(this.$route.query.id)
  },
  methods: {
    toInspectDetail(id) {
      this.$router.push({
        path: '../add',
        append: 'true',
        query: {
          'id': id, flag: 'report'
        }
      })
    },
    async handleDetail(id) {
      try {
        this.asetTableDatasLoading = true
        const { code, datas } = await auditById(id)
        if (code === 0) {
          this.ruleForm = datas
          this.ruleForm.auditReportVOList = this.ruleForm.auditReportVOList.map(item => {
            return { ...item, auditRate: item.auditRate ? item.auditRate * 100 : 0, sampleUnqualifiedRate: item.sampleUnqualifiedRate ? item.sampleUnqualifiedRate * 100 : 0 }
          })
          this.asetTableDatasLoading = false
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.asetTableDatasLoading = false
      }
    }

  }
}
</script>

<style scoped lang="scss">
</style>
